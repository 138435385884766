import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import Register from "../components/Account/Register";

const register = () => {
  return (
    <Layout>
      <SEO title="Register Page" />
      <Register />
    </Layout>
  );
};

export default register;
